import axios from "axios";
import * as Constants from "../../utils/constants";

export const getStores = async (token) => {
  try {
    const response = await axios.get(Constants.GET_STORES, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const updateStore = async (token, newStoreData) => {
  try {
    const response = await axios.patch(Constants.UPDATE_STORE, newStoreData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const createStore = async (token, storeData) => {
  try {
    const response = await axios.post(Constants.CREATE_STORE, storeData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getGondolas = async (token, store_id) => {
  try {
    const response = await axios.post(
      Constants.GET_GONDOLAS,
      { store_id: store_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getSections = async (token, gondola_id) => {
  try {
    const response = await axios.post(
      Constants.GET_SECTIONS,
      {
        gondola_id: gondola_id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getShelves = async (token, section_id) => {
  try {
    const response = await axios.post(
      Constants.GET_SHELVES,
      {
        section_id: section_id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const sales = async (token, file) => {
  try {
    const response = await axios.post(Constants.SALES, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.status;
  } catch (error) {
    return error.message;
  }
};

export const config = async (token, storeId) => {
  try {
    const response = await axios.post(
      Constants.CONFIG,
      { store_id: storeId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const cvStart = async (storeId) => {
  const parameters = {
    storeID: storeId,
  };
  try {
    const response = await axios.post(
      "https://d1lkvq3bgrh3av.cloudfront.net/run-cv",
      null,
      {
        params: parameters,
      }
    );
    return response.data;
  } catch (error) {
    return error.message;
  }
};
